import React from 'react'
import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import { Type } from '../../elements/Typography/Typography'
import { Presets } from '../../elements/Typography/Typography'
import { PartialBookingEngine } from '../../styles/types'
import { media } from '../../utils/media'

type RadioWrapperProps = {
  withBorder?: boolean
  checked?: boolean
}

const RadioWrapper = styled.label<RadioWrapperProps>`
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;
  padding: ${({ theme }) => theme.spacing[1]} 0;

  ${media.md(css`
    padding: ${({ theme }) => theme.spacing[0.5]} 0;
  `)}

  ${({ theme, withBorder, checked }) =>
    withBorder &&
    css`
      border: 1px solid ${checked ? colors.fountainBlue : '#d2d2d2'};
      padding: ${theme.spacing[1]} ${theme.spacing[1]};

      ${media.md(css`
        padding: ${theme.spacing[1]} ${theme.spacing[1]};
      `)}
    `}
`

const RadioDot = styled.div<{
  themeStyle?: PartialBookingEngine['massageCard']['radio']
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  min-height: 18px;
  width: 18px;
  min-width: 18px;
  border-radius: 50%;
  border: 1px solid
    ${props => props?.themeStyle?.radioDotBorderColor ?? colors.midGrey};
  position: relative;
  margin-right: ${({ theme }) => theme.spacing[1]};
  order: -1;

  ::after {
    background: ${props =>
      props?.themeStyle?.radioDotColor ?? colors.fountainBlue};
    height: 8px;
    width: 8px;
    border-radius: 50%;
  }
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  :checked + ${RadioDot} {
    ::after {
      content: '';
    }
  }

  :focus + ${RadioDot} {
    box-shadow: 0 0 0 2px ${colors.fountainBlue};
  }
`

export const SimpleRadio = ({
  id,
  name,
  label,
  value,
  ariaLabel = '',
  checked = false,
  withBorder = false,
  onChange,
  preset = 'textLarge' as Presets,
  themeStyle = undefined as PartialBookingEngine['massageCard']['radio'],
}) => {
  return (
    <RadioWrapper htmlFor={id} withBorder={withBorder} checked={checked}>
      <Type preset={preset}>{label}</Type>
      <Input
        id={id}
        name={name}
        type="radio"
        value={value}
        onChange={onChange || (() => {})}
        checked={checked}
        aria-label={ariaLabel}
      />
      <RadioDot themeStyle={themeStyle} />
    </RadioWrapper>
  )
}
