import { rgba } from 'polished'

import { breakpoints } from '../constants/breakpoints'
import { colors } from '../constants/colors'
import { base, modularScale } from '../constants/sizes'
import { between } from '../utils/between'
import { PartialBookingEngine } from './types'

/**
 *
 * @deprecated Use ui-primitives/src/utils/spacing instead
 */
export const createSpacing = (): Record<number, string> => {
  const obj = {}
  const smallest = -32
  const largest = 32
  const step = 0.5

  for (let val = smallest; val <= largest; val += step) {
    obj[val] = between((base / modularScale) * val, base * val)
  }

  return obj
}

const retreatBookingEngineTheme: PartialBookingEngine = {
  sidebar: {
    backgroundColor:
      'linear-gradient(135.91deg, rgba(96, 100, 105) 1.48%, rgba(156, 155, 155) 97.66%)',
  },
  flowScreen: {
    backgroundColor: colors.darkmode,
  },
  addProductCardField: {
    cardBackground: colors.darkmodeOffset,
    picker: {
      iconColor: colors.white,
    },
  },
  availableDateTimesField: {
    suggestionCard: {
      background: colors.darkmodeOffset,
      textColor: colors.white,
      selectColor: colors.blueOnDark,
    },
    textColor: colors.white,
  },
  breadcrumbField: {
    backButton: {
      color: colors.blueOnDark,
    },
    topBar: {
      background: colors.darkmode,
      borderColor: rgba(colors.white, 0.2),
    },
    pagination: {
      activeDash: colors.blueOnDark,
      inactiveDash: colors.white,
      textOpacity: 1,
      dashOpacity: 1,
      textColor: colors.blueOnDark,
      inactiveTextColor: colors.white,
      fadeColor: colors.darkmode,
      upperTitleColor: colors.white,
    },
    mobileTopBarCart: {
      iconColor: colors.blueOnDark,
      textColor: colors.blueOnDark,
      skeleton: {
        baseColor: colors.darkmodeOffset,
        highlightColor: colors.grey,
      },
    },
  },
  calendarField: {
    monthColor: colors.blueOnDark,
    disabledDayColor: colors.white,
    dayColor: colors.white,
    selectedDayColor: colors.white,
    selectedDayBackground: colors.deepBlue,
  },
  cardButtonField: {
    svgColor: colors.blueOnDark,
    card: {
      background: colors.darkmodeOffset,
      color: colors.white,
      focusBackground:
        'linear-gradient(136deg, rgba(96, 100, 105, 0.50) 1.48%, rgba(156, 155, 155, 0.50) 97.66%)',
      focusLabelBackground: colors.darkmode,
    },
    button: {
      color: colors.blueOnDark,
    },
    line: {
      background: colors.blueOnDark,
    },
  },
  cart: {
    currencySelector: {
      color: colors.blueOnDark,
      background: colors.darkmode,
    },
    buttonColor: colors.blueOnDark,
    backgroundColor: colors.darkmodeOffset,
    textColor: colors.white,
    borderColor: colors.white,
    mobileCart: {
      background: colors.darkmodeOffset,
      textColor: colors.white,
      closeIconColor: colors.white,
      backdropColor: colors.grey,
    },
  },
  confirmationDrawer: {
    totalPriceColor: colors.blueOnDark,
    summaryBorderColor: colors.white,
  },
  discountAccordionField: {
    accordion: {
      labelColor: colors.white,
    },
    addRowColor: colors.white,
    activeDiscountCode: {
      activeGiftCardColor: '#EEF6F8',
      activePromoColor: '#E8F4E9',
      textColor: colors.darkmode,
    },
  },
  dateTimeSelectField: {
    cardBackground: colors.darkmode,
    cardHeadingColor: colors.blueOnDark,
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.3)',
  },
  drawer: {
    scrollContainer: {
      background: colors.darkmode,
      color: colors.white,
    },
    header: {
      background: colors.darkmode,
      borderColor: rgba(colors.lightGrey, 0.25),
      closeButtonColor: colors.white,
    },
  },
  errorMessage: {
    color: '#E9B0B0',
  },
  inlineDatePicker: {
    backgroundColor: colors.darkmode,
    textColor: colors.white,
    dayColorLight: colors.deepBlue,
    dayColorDark: colors.deepBlue,
    dayHoverColor: rgba(colors.deepBlue, 0.4),
  },
  inputTextField: {
    input: {
      backgroundColor: colors.darkmodeOffset,
      borderColor: colors.grey,
      textColor: colors.white,
    },
  },
  itineraryField: {
    dotColor: colors.blueOnDark,
    extraItemButtonColor: colors.blueOnDark,
    closeButtonColor: colors.white,
  },
  massageCard: {
    cardBackground: colors.darkmode,
    cardHeadingColor: colors.blueOnDark,
    addButtonColor: colors.blueOnDark,
    deleteButtonColor: colors.white,
    clearButtonColor: colors.blueOnDark,
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.3)',
    radio: {
      radioDotColor: colors.blueOnDark,
      radioDotBorderColor: colors.white,
    },
  },
  phoneInputField: {
    input: {
      backgroundColor: colors.darkmodeOffset,
      borderColor: colors.grey,
      textColor: colors.white,
      arrowColor: colors.white,
      hoverCountryColor: colors.darkmode,
    },
  },
  pickerField: {
    picker: {
      textColor: colors.white,
      backgroundColor: colors.darkmodeOffset,
      borderColor: colors.grey,
      iconColor: colors.white,
    },
  },
  overviewField: {
    textColor: colors.white,
    borderColor: colors.grey,
  },
  selectDayVisitTimeField: {
    tableRowColor: colors.white,
    tableRowHoverBackground: colors.darkmodeOffset,
    evenRowColor: 'transparent',
    rightColumnColor: colors.blueOnDark,
    rightColumnHoverColor: colors.blueOnDark,
    rightColumnSelectedColor: colors.blueOnDark,
    dateSelectorColor: colors.blueOnDark,
    selectedIconColor: colors.blueOnDark,
    columnLabelsBorderColor: rgba(colors.white, 0.2),
  },
  selectField: {
    select: {
      backgroundColor: colors.darkmodeOffset,
      borderColor: colors.grey,
      textColor: colors.white,
      hoverBorderColor: colors.blueOnDark,
    },
  },
  simpleAccordion: {
    labelColor: colors.blueOnDark,
    contentColor: colors.white,
    lineColor: colors.deepBlue,
  },
  spaBookingBar: {
    backgroundColor: colors.darkmode,
    color: colors.white,
    fixedMobileBarBackground: colors.darkmodeOffset,
  },
  currencySelector: {
    color: colors.blueOnDark,
    background: colors.darkmodeOffset,
  },
  textAreaField: {
    textArea: {
      backgroundColor: colors.darkmodeOffset,
      textColor: colors.white,
      borderColor: colors.grey,
    },
  },
  transportationFlow: {
    locationPicker: {
      locationSearch: {
        searchInput: {
          frameBackground: 'linear-gradient(90deg, #282A2C 0%, #7C8083 97.99%)',
          dropDown: {
            backgroundColor: colors.darkmodeOffset,
            textColor: colors.white,
            hoverColor: colors.blueOnDark,
          },
          input: {
            backgroundColor: colors.darkmodeOffset,
            textColor: colors.white,
            iconColor: colors.white,
            placeholderColor: rgba(colors.white, 0.8),
          },
        },
        locationList: {
          listItem: {
            textColor: colors.white,
            selectedTextColor: colors.white,
            selectedBackground: colors.blueOnDark,
            checkmarkColor: colors.white,
          },
        },
      },
    },
    map: {
      infoBox: {
        background: colors.darkmode,
        closeIconColor: colors.white,
        disclaimerIconColor: colors.blueOnDark,
      },
    },
  },
  confirmModal: {
    backgroundColor: colors?.darkmode,
    lineColor: colors?.blueOnDark,
    textColor: colors?.white,
    buttonPreset: 'blue',
    secondaryButtonPreset: 'transparentWithBlueBorderOnDark',
  },
  checkoutProgress: {
    backgroundColor: colors.darkmodeOffset,
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.3)',
    pendingColor: colors.grey,
    inProgressColor: colors.blueOnDark,
    completeColor: colors.blueOnDark,
  },
}

const bookingEngine = {
  retreat: retreatBookingEngineTheme,
}

const bookingEngineScreenThemes = {
  default: {
    background: colors.backgroundWhite,
    color: colors.midGrey,
    buttonPreset: 'primary',
  },
  blue: {
    background: 'linear-gradient(90deg, #6793AE 0%, #50A7BA 97.99%)',
    color: colors.white,
    buttonPreset: 'lightGrey',
  },
  retreat: {
    background: colors.darkmode,
    color: colors.white,
    buttonPreset: 'primary',
  },
}

// Currently only used in bluelagoon-website
export const theme = {
  breakpoints,
  spacing: createSpacing(),
  bookingEngineScreenThemes,
  bookingEngine,
}
